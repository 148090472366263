<template>
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.589844 10.59L5.16984 6L0.589843 1.41L1.99984 -6.16331e-08L7.99984 6L1.99984 12L0.589844 10.59Z"
    />
  </svg>
</template>
